<template>
  <div class="marketing-phone members-directory">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.push({ name: 'BillingInvoice' })" class="text-capitalize
              btn customBtn pattern-btn
              white--text" elevation="0">
            <span> {{ $t("invoice.back") }}</span>
          </button>
        <h3 class="box-title green-text">
            {{ $t("invoice.invoiceTitle") }} 
          </h3>
          <v-spacer v-if="$vuetify.breakpoint.width < 550" />
          <h2 class="car-count" v-if="$vuetify.breakpoint.width < 550">
            <span>{{ (invoiceList.meta && invoiceList.meta.total) || 0 }}</span>
          </h2>
        <button v-if="$vuetify.breakpoint.width < 550" @click="filterDialog = true" class="btn customBtn pattern-btn text-capitalize"
          elevation="0">
          <span> {{ $t("invoice.filter") }}</span>
        </button>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.width > 550" />
          <div class="box-top-right"></div>
          <h2 v-if="$vuetify.breakpoint.width > 550" class="car-count mr-2">
            <span>{{ (invoiceList.meta && invoiceList.meta.total) || 0 }}</span>
          </h2>
        <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
          :placeholder="$t('posts.searchForWords')" solo @input="fetchInvoiceList" append-icon="mdi-magnify">
        </v-text-field>
        <button v-if="$vuetify.breakpoint.width > 550" @click="filterDialog = true" class="btn customBtn pattern-btn text-capitalize ml-2 mr-2"
          elevation="0">
          <span> {{ $t("invoice.filter") }}</span>
        </button>
        <button @click="exportReport" class="btn customBtn pattern-btn text-capitalize" elevation="0">
          <span> {{ $t("invoice.download") }}</span>
        </button>
        </div>
      </div>

      <div class="custom-card inside-model-box">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :single-expand="singleExpand" fixed-header
            :disable-sort="true" :show-expand="false" @page-count="pageCount = $event" :loading="loading" item-key="id"
            class="color-light-green" :headers="headers" :items="invoiceList.data" :expanded.sync="expanded" show-expand
            :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
              <div class="expand-row-inr-wrap">
                <v-row>
                  <v-col cols="12" md="4">
                    <template v-if="item.customer && item.customer.customer_name">
                      <b>{{ $t("invoice.invoiceTable.customerName") }}</b> :
                      {{ item.customer.customer_name | UCFirstChars }}<br />
                    </template>
                    <template v-if="item.customer && item.customer.customer_phone">
                      <b>{{ $t("invoice.invoiceTable.customerPhone") }}</b> :
                      {{ item.customer.customer_phone | USFormatNumber }}<br />
                    </template>
                    <template v-if="item.customer && item.customer.business_name">
                      <b>{{ $t("invoice.invoiceTable.businessName") }}</b> :
                      {{ item.customer.business_name | UCFirstChars }}<br />
                    </template>
                    <template v-if="item.customer && item.customer.business_phone">
                      <b>{{ $t("invoice.invoiceTable.businessPhone") }}</b> :
                      {{ item.customer.business_phone | USFormatNumber }}<br />
                    </template>
                    <template v-if="item.customer && item.customer.address">
                      <b>{{ $t("invoice.invoiceTable.customerAddress") }}</b> :<br />
                      {{ item.customer.address }}
                      {{ item.customer.zip }}<br />
                    </template>
                    <template v-if="item.customer && item.customer.ship_address">
                      <b>{{ $t("invoice.invoiceTable.shipAddress") }}</b> :<br />
                      {{ item.customer.ship_customer_name | UCFirstChars }}<br />
                      <span v-if="item.customer.ship_customer_phone"><b>T:</b> {{ item.customer.ship_customer_phone |
                        USFormatNumber }}</span><br />
                      {{ item.customer.ship_address }}
                      {{ item.customer.ship_zip }}<br />
                      <span v-if="item.customer.ship_note"><b>Note:</b> {{ item.customer.ship_note }}</span><br />
                    </template>
                    <b>{{ $t("invoice.invoiceTable.salesPerson") }}</b> :
                    {{ item.sales_person_name | UCFirstChars }}<br />
                    <template v-if="item.customer && item.customer.tax_resale_number">
                      <b>{{ $t("invoice.invoiceTable.taxNumber") }}</b> :
                      {{ item.customer.tax_resale_number }}<br /><br />
                    </template>
                    <template v-if="getOverDue(item)">
                      <b>{{ $t("invoice.invoiceTable.overDue") }}</b> :
                      {{ getOverDue(item) }}<br />
                    </template>
                    <template v-if="item.notes">
                      <b>{{ $t("invoice.createForm.internalDismantlerNotes") }}</b> :<br />
                      {{ item.notes }}<br />
                    </template>
                  </v-col>
                  <v-col cols="12" md="8">
                    <template>
                      <div class="mt-2 mb-2"><b>{{ $t("invoice.createForm.partsPurchase") }} :</b></div>
                      <table width="100%" class="inr-custom-table">
                        <thead class="text-center white--text">
                          <tr>
                            <th width="25%">{{ $t("invoice.createForm.name") }}</th>
                            <th width="30%">{{ $t("invoice.createForm.yearModel") }}</th>
                            <th width="15%">{{ $t("invoice.createForm.warranty") }}</th>
                            <th width="5%">{{ $t("invoice.createForm.isRefunded") }}</th>
                            <th width="15%">{{ $t("invoice.createForm.amount") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in item.items" :key="index">
                            <td>
                              {{ item.part_name }}</td>
                            <td>
                              {{ item.year }} {{ item.make }} {{ item.model }}</td>
                            <td
                              class="text-center">{{ item.warranty }}</td>
                            <td
                              class="text-center red--text">{{ (item.is_refunded == 1) ? 'Yes' : '' }}</td>
                            <td
                              class="text-right">{{ (item.is_refunded == 1) ? -Math.abs(item.price) : item.price |
                                USCurrencyfilter }}</td>
                          </tr>
                          <tr style="font-weight: bold;">
                            <td></td>
                            <td class="text-right" colspan="3">{{
                                $t("invoice.invoiceTable.subTotal") }}:</td>
                            <td class="text-right">
                              {{ getItemTotal(item.items) | USCurrencyfilter }}</td>
                          </tr>
                          <tr style="font-weight: bold;">
                            <td></td>
                            <td class="text-right" colspan="3">{{
                                $t("invoice.invoiceTable.discount") }} ({{ item.discount }}%):</td>
                            <td class="text-right">
                              {{ getDiscountAmount(item) | USCurrencyfilter }}</td>
                          </tr>
                          <tr style="font-weight: bold;">
                            <td></td>
                            <td class="text-right" colspan="3">{{
                                $t("invoice.createForm.subtotalAfterDiscount") }}:</td>
                            <td class="text-right">
                              {{ (getItemTotal(item.items) - getDiscountAmount(item)) | USCurrencyfilter }}</td>
                          </tr>
                          <tr style="font-weight: bold;">
                            <td></td>
                            <td class="text-right" colspan="3">{{
                                $t("invoice.invoiceTable.salesTax") }}:</td>
                            <td class="text-right">
                              {{ getSalesTaxAmount(item) | USCurrencyfilter }}</td>
                          </tr>
                          <tr style="font-weight: bold;">
                            <td></td>
                            <td class="text-right" colspan="3">{{
                                $t("invoice.invoiceTable.shippingCharges") }}:</td>
                            <td class="text-right">
                              {{ item.shipping_charges | USCurrencyfilter }}</td>
                          </tr>
                          <tr style="font-weight: bold;">
                            <td></td>
                            <td class="text-right" colspan="3">{{
                                $t("invoice.invoiceTable.totalAmount") }}:</td>
                            <td class="text-right">
                              {{ getTotal(item) | USCurrencyfilter }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                    <template v-if="item.payment_history">
                      <div class="mt-2 mb-2"><b>{{ $t("invoice.invoiceTable.paymentHistory") }}</b> :</div>
                      <template>
                        <table width="100%" class="inr-custom-table">
                          <tbody>
                            <tr class="white--text">
                              <th width="34%">{{ $t("invoice.invoiceTable.date") }}</th>
                              <th width="33%">{{ $t("invoice.invoiceTable.payments") }}</th>
                              <th width="33%">{{ $t("invoice.invoiceTable.pending") }}</th>
                            </tr>
                            <template v-for="(payment, index) in JSON.parse(item.payment_history)">
                              <tr class="text-center">
                                <td>
                                  {{ payment.date | DateTimezoneFilter }}</td>
                                <td>
                                  {{ payment.amount | USCurrencyfilter }}</td>
                                <td>
                                  {{ getPendingAmount(item, item.payment_history, index) | USCurrencyfilter }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </template>
                    </template>
                  </v-col>
                </v-row>
              </div>
              </td>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span class="datebreak date-time">
             <span> {{ item.created_at | DateTimezoneFilter }}</span>
            </span>
            </template>
            <!-- <template v-slot:item.invoice_number="{ item }">
              {{ item.invoice_number }}
            </template> -->
            <template v-slot:item.customer_type="{ item }">
              {{ item.customer_type | UCFirstChars }}
            </template>
            <template v-slot:item.invoice_status="{ item }">
              <v-chip v-if="getInvoiceStatus(item) == 'Paid'" color="success" small label
              @click="onExpand(item)"> 
                <span class="expand-arrow-wrap mr-2">{{ getInvoiceStatus(item) }}</span>
                  <v-btn class="expand-arrow1 white--text" :ripple="false" icon small v-bind="attrs" v-on="on">
                    <v-icon medium>{{ expandedIcon[item.id] ? expandedIcon[item.id] : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
              </v-chip>
              <v-chip v-if="getInvoiceStatus(item) == 'Partial Paid'" color="success" small label @click="onExpand(item)"> 
                <span class="expand-arrow-wrap mr-2">{{ getInvoiceStatus(item) }} </span>
                  <v-btn class="expand-arrow1" :ripple="false" icon small v-bind="attrs" v-on="on"
                    >
                    <v-icon medium>{{ expandedIcon[item.id] ? expandedIcon[item.id] : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
              </v-chip>
              <v-chip v-if="getInvoiceStatus(item) == 'Unpaid'" color="success" small label @click="onExpand(item)"> 
                <span class="expand-arrow-wrap mr-2">{{ getInvoiceStatus(item) }}</span>
                  <v-btn class="expand-arrow1" :ripple="false" icon small v-bind="attrs" v-on="on"
                    >
                    <v-icon medium>{{ expandedIcon[item.id] ? expandedIcon[item.id] : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
              </v-chip>
              <v-chip v-if="getInvoiceStatus(item) == 'Refunded'" color="red" class="white--text" small label @click="onExpand(item)"> 
                <span class="expand-arrow-wrap mr-2">{{ getInvoiceStatus(item) }} </span>
                  <v-btn class="expand-arrow1 white--text" :ripple="false" icon small v-bind="attrs" v-on="on"
                    >
                    <v-icon medium>{{ expandedIcon[item.id] ? expandedIcon[item.id] : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
              </v-chip>
              <v-chip v-if="getInvoiceStatus(item) == 'Workorder'" color="success" class="white--text" small label @click="onExpand(item)"> 
                <span class="expand-arrow-wrap mr-2">{{ getInvoiceStatus(item) }} </span>
                  <v-btn class="expand-arrow1 white--text" :ripple="false" icon small v-bind="attrs" v-on="on"
                    >
                    <v-icon medium>{{ expandedIcon[item.id] ? expandedIcon[item.id] : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
              </v-chip>
            </template>
            <template v-slot:item.subtotal="{ item }">
              <!-- {{ item.subtotal | USCurrencyfilter }} -->
              {{ getTotal(item) | USCurrencyfilter }}
            </template>
            <template v-slot:item.sales_tax_amount="{ item }">
              {{ getSalesTaxAmount(item) | USCurrencyfilter }}
            </template>
            <template v-slot:item.pending_amount="{ item }">
              {{ getTotalPendingAmount(item) | USCurrencyfilter }}
            </template>
            <template v-slot:item.payment_due_days="{ item }">
              {{ getOverDue(item) }}
            </template>
            <template v-slot:item.data-table-expand="{ item }">
              <div class="action-btn-row">
                <v-tooltip  v-if="item.status == 1 || item.status == 3 || item.status == 5">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2 action-btn" :ripple="false" icon small v-bind="attrs" v-on="on"
                    @click="showRefundPaymentDailog(item)">
                    <v-icon medium>mdi-share</v-icon>
                  </v-btn>
                </template>
                <span>Returns/Payments</span>
              </v-tooltip>
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="action-btn" :ripple="false" icon small v-bind="attrs" v-on="on"
                    @click="printInvoice(item)">
                    <v-icon medium>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Print/Email</span>
              </v-tooltip>
              <!-- <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="action-btn" @click="$router.push({ name: 'InvoiceView', params: { invoiceId: item.id } })" :ripple="false" icon small v-bind="attrs" v-on="on"
                    >
                    <v-icon medium>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip> -->
              </div>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="inside-model-boxs">
        <v-dialog hide-overlay :attach="true" v-model="filterDialog" max-width="500px" :content-class="'rd-custom-modal'"
          persistent>
          <v-card>
            <v-card-title> 
              <h4>{{ $t("invoice.invoiceTable.filterTitle") }}</h4>
              <button class="close-btn" @click="filterDialog = !filterDialog">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-form>
              <div class="filter-invoice">
                <v-form>
                  <v-card-text>
                    <div class="mb-5">
                      <date-range-picker ref="picker" opens="right" :ranges="ranges()" :locale-data="{
                        direction: 'ltr',
                        format: 'mm-dd-yyyy',
                        separator: ' - ',
                        applyLabel: 'Apply',
                        cancelLabel: 'Cancel',
                        weekLabel: 'W',
                        customRangeLabel: 'Custom Range',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0
                      }" v-model="dates" :alwaysShowCalendars="false" controlContainerClass="noneBorder">
                        <template v-slot:input="picker">
                          <v-text-field hide-details="auto" :placeholder="$t('invoice.createForm.date')"
                            append-icon="mdi-calendar" readonly elevation="0" height="40" solo dense
                            :value="setDates(picker)"></v-text-field>
                        </template>
                      </date-range-picker>
                    </div>
                    <v-select :items="invoiceTypeOption" item-text="value" item-value="key" v-model="invoice_type"
                      :placeholder="$t('invoice.createForm.invoiceType')" elevation="0" height="40" solo
                      dense></v-select>
                    <v-select :items="customerTypeOption" item-text="value" item-value="key" v-model="customer_type"
                      :placeholder="$t('invoice.createForm.customerType')" elevation="0" height="40" solo
                      dense></v-select>
                    <v-select v-if="invoiceAssociateUsersList && invoiceAssociateUsersList.length > 0"
                      :items="invoiceAssociateUsersList" item-text="name" item-value="id" v-model="sales_person"
                      :placeholder="$t('invoice.createForm.salesPerson')" elevation="0" height="40" solo hide-details
                      dense></v-select>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize"
                      @click="filterDialog = !filterDialog">
                      <span> {{ $t("invoice.close") }}</span>
                    </v-btn>
                    <v-btn
                      v-if="invoice_type != null || customer_type != null || sales_person != null || (dates.startDate && dates.endDate)"
                      class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize" @click="clearFilter">
                      <span>{{ $t("invoice.clear") }}</span>
                    </v-btn>
                    <v-btn class="btn customBtn pattern-btn ml-2 white--text text-capitalize" @click="fetchInvoiceList">
                      <span> {{ $t("invoice.apply") }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </div>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog hide-overlay :attach="true" v-model="returnPayDailog" max-width="500px"
          :content-class="'rd-custom-modal'" persistent>
          <v-card>
            <v-card-title> 
              <h4>{{ $t("invoice.invoiceTable.refundPaymentTitle") }}</h4>
              <button class="close-btn" @click="returnPayDailog = !returnPayDailog;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
                <v-form>
            <div class="">
                    <v-form>
                      <v-card-text>
                      <v-radio-group v-model="status" row class="custom-pdm mt-2 mb-5" hide-details="auto">
                        <div class="interchange-radio">
                        <v-radio :label="$t('invoice.invoiceTable.refund')" value="refund"></v-radio>
                        <v-radio v-if="currunt_status == 'Partial Paid' || currunt_status == 'Unpaid'"
                          :label="$t('invoice.invoiceTable.partialPayment')" value="payment"></v-radio>
                        </div>
                      </v-radio-group>
                      <div v-if="status == 'refund'">
                        <p class="not-carry mb-4"><span v-html="$t('invoice.invoiceTable.refundNote')"></span></p>
                        <table width="100%" class="inr-custom-table">
                          <thead class="text-center white--text">
                            <tr>
                              <th width="5%">
                                <v-checkbox class="pl-1 pr-0 pt-1 pb-1 mt-0" style="height: 25px;display:none;"
                                  v-model="selectAll"></v-checkbox>
                              </th>
                              <th width="25%">{{ $t("invoice.createForm.name") }}</th>
                              <th width="45%">{{ $t("invoice.createForm.yearModel") }}</th>
                              <!-- <th width="15%">{{ $t("invoice.createForm.warranty") }}</th> -->
                              <th width="15%">{{ $t("invoice.createForm.amount") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(i, index) in selected_invoice.items" :key="index" v-if="i.is_refunded == 0">
                              <td>
                                <v-checkbox class="pr-0 pt-0 mt-0" style="height: 25px" v-model="selectedItems"
                                  :value="i.id"></v-checkbox>
                              </td>
                              <td>
                                {{ i.part_name }}</td>
                              <td>
                                {{ i.year }} {{ i.make }} {{ i.model }}</td>
                              <!-- <td class="text-center">{{ i.warranty }}</td> -->
                              <td class="text-right">{{ i.price | USCurrencyfilter }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="field">
                    <v-text-field type="number" v-if="status == 'payment'" v-model="amount"
                        :placeholder="$t('invoice.invoiceTable.amount')" solo dense hide-details="auto" />
                      </div>
                        </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize"
                          @click="returnPayDailog = !returnPayDailog;">
                         <span> {{ $t("invoice.close") }}</span>
                        </v-btn>
                        <v-btn 
                          class="btn customBtn pattern-btn ml-2 white--text text-capitalize" :disabled="isSubmitValid()" @click="refundPayConfirm()" :loading="saving">
                          {{ $t("invoice.invoiceTable.submit") }}
                        </v-btn>
                      </v-card-actions>
                    </v-form>
            </div>
                </v-form>
          </v-card>
        </v-dialog>

        <v-dialog hide-overlay :attach="true" v-model="returnConfirmDailog" max-width="800px"
          :content-class="'image-slider'" persistent>
          <v-card>
            <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
              <div class="pa-2">
                <v-card-title> {{ $t("invoice.invoiceTable.refundConfirmTitle") }}</v-card-title>
                <v-form>
                  <v-container>
                    <v-form v-if="selectedItems.length > 0">
                      <ul
                        v-for="(item, index) of selected_invoice.items.filter(function (i) { if (selectedItems.includes(i.id)) return i; })">
                        <li v-if="item" style="font-size:20px;">{{ index + 1 }}. {{ item.part_name ?? '' }} {{ item.year
                          }} {{
                            item.make }} {{ item.model }}</li>
                      </ul>
                      <br>
                      <hr><br>
                      <h1>{{ $t("invoice.invoiceTable.refundConfirmSubmitNote") }}</h1>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize mt-5"
                          @click="returnPayDailog = !returnPayDailog; returnConfirmDailog = !returnConfirmDailog;">
                          {{ $t("invoice.no") }}
                        </v-btn>
                        <v-btn height="40" large color="green darken-4 green-gradient white--text text-capitalize"
                          class="mt-5" @click="updateInvoiceInfo()" :loading="saving">
                          {{ $t("invoice.yes") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-form>
                  </v-container>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        </div>

      <v-snackbar v-model="snackbarSuccess" :timeout="3000" absolute top color="success" right>
        {{ this.apiErrorMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarSuccess = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",


      company_id: null,
      images: [],
      loading: false,
      page: 1,
      searchText: "",
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      expandedIcon: [],

      snackbarSuccess: false,
      filterDialog: false,
      invoice_type: null,
      customer_type: null,
      sales_person: null,
      menu2: false,
      dates: {
        startDate: null,
        endDate: null,
      },

      invoiceTypeOption: [
        { key: 1, "value": "Paid" },
        { key: 3, "value": "Partial Paid" },
        { key: 'unpaid', "value": "Unpaid" },
        { key: 4, "value": "Refunded" },
        { key: "overdue", "value": "Overdue" },
        { key: 2, "value": "Workorder" },
      ],
      customerTypeOption: [
        { key: "body_shop", "value": "Body Shop" },
        { key: "dismantler", "value": "Dismantler" },
        { key: "customer", "value": "Customer" }
      ],
      invoiceAssociateUsersList: [],

      saving: false,
      returnPayDailog: false,
      returnConfirmDailog: false,
      currunt_status: null,
      selected_invoice: null,
      selectedItems: [],
      invoice_id: null,
      status: null,
      amount: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },

    ...mapGetters({
      user: "user/getUser",
      invoiceList: "invoice/getInvoiceList",
      invoiceAssociateUsers: "invoice/getInvoiceAssociateUsers"
    }),
    headers() {
      return [
        {
          text: this.$t("invoice.invoiceTable.invoiceStatus"),
          align: "left",
          value: "invoice_status",
          width: "10%",
        },
        {
          text: this.$t("invoice.invoiceTable.date"),
          align: "left",
          value: "created_at",
          width: "12%",
        },
        {
          text: this.$t("invoice.invoiceTable.customerType"),
          align: "left",
          value: "customer_type",
          width: "13%",
        },
        {
          text: this.$t("invoice.invoiceTable.totalAmount"),
          align: "left",
          value: "subtotal",
          width: "9%",
        },
        {
          text: this.$t("invoice.invoiceTable.salesTax"),
          align: "left",
          value: "sales_tax_amount",
          width: "9%",
        },
        {
          text: this.$t("invoice.invoiceTable.pendingAmount"),
          align: "left",
          value: "pending_amount",
          width: "9%",
        },
        {
          text: this.$t("invoice.invoiceTable.action"),
          value: "data-table-expand",
          width: "12%",
        },
      ];
    },
    selectAll: {
      get: function () {
        return this.selected_invoice.items ? this.selectedItems.length == this.selected_invoice.items.length : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.selected_invoice.items.forEach(function (item) {
            if (item.is_refunded == 0) {
              selected.push(item.id);
            }
          });
        }
        this.selectedItems = selected;
      }
    }
  },
  async mounted() {
    if (this.user && this.user.user_detail) {
      if (this.user.parent_id) {
        this.company_id = this.user.parent_id;
      } else {
        this.company_id = this.user.id;
      }
    }

    console.log(this.company_id);
    await this.fetchInvoiceAssociateUsers();
    await this.fetchInvoiceList();
  },
  methods: {
    ...mapActions({
      getInvoiceList: "invoice/getInvoiceList",
      updateInvoice: "invoice/updateInvoice",
      getInvoiceAssociateUsers: "invoice/getInvoiceAssociateUsers",
    }),

    async fetchInvoiceAssociateUsers() {
      this.loading = true;
      try {
        await this.getInvoiceAssociateUsers({});

        this.invoiceAssociateUsersList = this.invoiceAssociateUsers.data.map(function (val) {
          return {
            id: val.id,
            name: val.user_detail.representative_name
          };
        });
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchInvoiceList(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchInvoiceList(); // Fetch new data based on the current page
      }
    },

    async fetchInvoiceList() {
      this.filterDialog = false;
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        let dateFilter = [moment(this.dates.startDate).format("YYYY-MM-DD"), moment(this.dates.endDate).format("YYYY-MM-DD")];
        await this.getInvoiceList({
          search: this.searchText,
          page: this.currentPage,
          items_per_page: this.perPage,
          filter: {
            invoice_type: this.invoice_type,
            customer_type: this.customer_type,
            user_id: this.sales_person,
            invoice_dates: this.dates.startDate ? dateFilter : [],
          }
        });

        if (this.invoiceList.meta) {
          this.totalEntries = this.invoiceList.meta.total;
          this.itemsPerPage = parseInt(this.invoiceList.meta.per_page);
          this.pageCount = this.invoiceList.meta.last_page;
        } else {
          this.itemsPerPage = this.invoiceList.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    setDates(picker) {
      let startDate = (picker.startDate ? moment(picker.startDate).format('MM-DD-YYYY') + ' - ' : '');
      let endDate = (picker.endDate ? moment(picker.endDate).format('MM-DD-YYYY') : '');
      return startDate + endDate;
    },
    ranges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);
      var y = today.getFullYear(), m = today.getMonth();
      var thisMonthStart = new Date(y, m, 1);
      let thisMonthEnd = new Date(y, m + 1, 0, 11, 59, 59, 999);

      return {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last 7 Days': [new Date(y, m, today.getDate() - 6), today],
        'Last 30 Days': [new Date(y, m, today.getDate() - 29), today],
        'This year': [new Date(y, 0, 1), new Date(y, 11, 31)],
        'Last month': [new Date(y, m - 1, 1), new Date(y, m, 0)],
      }
    },
    clearFilter() {
      this.invoice_type = null;
      this.customer_type = null;
      this.sales_person = null;
      this.dates = {
        startDate: null,
        endDate: null,
      };
      this.fetchInvoiceList();
      this.filterDialog = !filterDialog;
    },
    onExpand(value) {
      const index = this.expanded.indexOf(value)
      if (index === -1) {
        this.expandedIcon[value.id] = 'mdi-chevron-up';
        this.expanded.push(value);
      } else {
        this.expandedIcon[value.id] = 'mdi-chevron-down';
        this.expanded.splice(index, 1)
      }
    },
    getInvoiceStatus(item) {
      if (item.status == 4) {
        return "Refunded";
      } if (item.status == 2) {
        return "Workorder";
      } else {
        if (item.payment_term == 'full_charge') {
          return 'Paid';
        } else if (item.payment_term == 'billing_payments') {
          if (item.deposit_amount == 0) {
            return "Unpaid";
          } else if (item.deposit_amount < this.getTotal(item)) {
            return "Partial Paid";
          } else {
            return 'Paid';
          }
        }
      }
    },
    getTotalPendingAmount(item) {
      if (item.status == 4 || item.status == 2 || item.payment_term == 'full_charge') {
        return "0";
      } else if (item.payment_term == 'billing_payments') {
        return (this.getTotal(item) - item.deposit_amount).toFixed(2);
      }
    },
    showRefundPaymentDailog(item) {
      this.returnPayDailog = true;
      this.invoice_id = item.id;
      this.currunt_status = this.getInvoiceStatus(item);
      this.selected_invoice = item;
      this.selectedItems = [];
    },
    async printInvoice(item) {
      this.$store.commit("setLoader", true);
      try {
        await this.$router.push({ name: 'BillingInvoiceView', params: { invoice_id: item.id } });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async exportReport() {
      this.$store.commit("setLoader", true);
      try {
        let payload = {
          search: this.searchText,
          invoice_type: this.invoice_type,
          customer_type: this.customer_type,
          user_id: this.sales_person,
          start_date: this.dates.startDate ? moment(this.dates.startDate).format("YYYY-MM-DD") : '',
          end_date: this.dates.endDate ? moment(this.dates.endDate).format("YYYY-MM-DD") : '',
          company_id: this.company_id
        };
        console.log(payload);
        await this.$router.push({ name: 'BillingInvoiceReport', params: { ...payload } });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    isSubmitValid() {
      if (this.status == 'refund' && this.selectedItems.length != 0) {
        return false;
      } else if (this.status == 'payment' && this.amount != 0 && this.amount != null) {
        return false;
      } else {
        return true;
      }
    },
    refundPayConfirm() {
      if (this.status == 'refund') {
        this.returnPayDailog = false;
        this.returnConfirmDailog = true;
      } else {
        this.updateInvoiceInfo();
      }
    },
    updateInvoiceInfo() {
      try {
        this.saving = true;
        this.updateInvoice({
          invoice_id: this.invoice_id,
          status: this.status,
          amount: this.amount,
          items: this.selectedItems
        });

        this.apiErrorMessage = "Invoice " + this.status + " saved successfully.";
        this.returnPayDailog = false;
        this.returnConfirmDailog = false;
        this.snackbarSuccess = true;
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.saving = false;
      }
    },
    getOverDue(item) {
      var start = moment(item.created_at);
      var end = moment();
      var diffDays = end.diff(start, "days");
      var overdueDays = diffDays - item.payment_due_days;
      if (item.payment_due_days && diffDays) {
        if (overdueDays > 0 && parseFloat(this.getTotalPendingAmount(item)) > 0) {
          return item.payment_due_days + ' D' + ' / ' + overdueDays + ' D';
        } else {
          return item.payment_due_days + ' D';
        }
      } else if (item.payment_due_days) {
        return item.payment_due_days + ' D';
      }
      return;
    },
    getPendingAmount(item, payment_history, index) {
      let total = this.getTotal(item);
      let payments = JSON.parse(payment_history);
      let totalDepositAmount = 0;
      for (let i = 0; i <= index; i++) {
        totalDepositAmount = totalDepositAmount + parseFloat(payments[i].amount);
      }
      return total - totalDepositAmount;
    },
    getTotal(item) {
      return ((this.getItemTotal(item.items) + this.getSalesTaxAmount(item) + item.shipping_charges) - this.getDiscountAmount(item));
    },
    getItemTotal(items) {
      let itemTotal = 0;
      items.forEach(item => {
        if (item.is_refunded == 0) {
          itemTotal = itemTotal + parseFloat(item.price);
        }
      });
      return itemTotal;
    },
    getDiscountAmount(item) {
      return ((this.getItemTotal(item.items) * item.discount) / 100);
    },
    getSalesTaxAmount(item) {
      return (item.sales_tax_amount); //-item.refunded_tax_amount);
    }
  },
};
</script>
<style scoped>
/* .v-dialog__content {
  position: absolute;
}

.post-inventory-div {
  display: flex;
  align-items: center;
}

.vue-daterange-picker {
  display: unset;
}

.noneBorder {
  border: none !important;
  width: 100%;
}

ul li {
  list-style-type: none;
} */
</style>